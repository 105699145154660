import {generatePath} from "react-router-dom";
import {ProfilePageTab} from "./pages/ProfilePage";
import _sanitizeHtml, {AllowedAttribute} from 'sanitize-html';

function allowAttributes(record: Record<string, AllowedAttribute[]>, element: string, ...attributes: string[]) {
    let existingArr = record[element] ?? []
    record[element] = existingArr.concat(attributes)
}

const allowedAttributes = _sanitizeHtml.defaults.allowedAttributes
const tags = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "div", "img"]
tags.forEach((t) => {
    allowAttributes(allowedAttributes, t, "style", "align")
})

const sanitizeHtmlOptions = {
    allowedTags: _sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
    allowedSchemes: _sanitizeHtml.defaults.allowedSchemes.concat([ 'data' ]),
    allowedAttributes: allowedAttributes,
    parseStyleAttributes: false
}


export function sanitizeHtml(str: string): string {
    return _sanitizeHtml(str, sanitizeHtmlOptions)
}

export function formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp)
    return date.toLocaleDateString(window.navigator.language, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    })
}

export function formatDate(timestamp: string): string {
    const date = new Date(timestamp)
    return date.toLocaleDateString(
        window.navigator.language,
        {month: "2-digit", day: "2-digit", year: "2-digit"}
    )
}

export function parseIntOrNull(str: string): number | null {
    const value = parseInt(str, 10)
    if (isNaN(value)) return null
    return value
}

export function generateProfilePath(communityId: string, tab?: ProfilePageTab): string {
    return generatePath("/user/:communityId/:tab?", {
        communityId: communityId,
        tab: tab ?? null
    })
}

export function divisionIdentifierToDisplayName(identifier: string): string {
    return identifier.replaceAll("_", " ").replaceAll("-", " ").split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function displayNameToIdentifier(displayName: string): string {
    return displayName.replaceAll(" ", "_").toLowerCase()
}

export function pluralize(s: string, num: number): string {
    if (num === 1 || num === -1) {
        return s
    } else {
        return s + "s"
    }
}

export function browserHasWebSocketSupport(): boolean {
    if (!WebSocket) return false
    return true
}

export function createWebSocket(path: string): WebSocket | null {
    if (!browserHasWebSocketSupport()) return null
    const protocolPrefix = (window.location.protocol === 'https:') ? 'wss:' : 'ws:';
    return new WebSocket(protocolPrefix + '//' + window.location.host + path)
}

const steamIdRegex = RegExp("^STEAM_[0-1]:[0-1]:[0-9]+$")

export function isSteamId(steamId: string): boolean {
    return steamIdRegex.test(steamId)
}

export function communityIdToSteamId(communityId: string): string {
    const bigintCommunityId = BigInt(communityId)
    const y = bigintCommunityId & BigInt(1)
    const accountNumber = (bigintCommunityId & BigInt(0xFFFFFFFF)) >> BigInt(1)
    return `STEAM_0:${y}:${accountNumber}`
}

export function steamIdToCommunityId(steamId: string): string {
    const tmpId = steamId.substring(8).split(":")
    return (BigInt(tmpId[0]) + BigInt(tmpId[1]) * BigInt(2) + BigInt("76561197960265728")).toString()
}

export function clamp(x: number, min: number, max: number): number {
    if (x < min) {
        return min
    } else if (x > max) {
        return max
    } else {
        return x
    }
}

export function reduceLargeTextInput(text: string, width?: number): string {
    let newText = ""
    let currentLine = ""
    let totalCharLimit = width ?? 75
    let charsInCurrentWord = 30
    for (let i = 0; i < text.length; i++) {
        let char = text[i]
        if (char !== " ") {
            charsInCurrentWord -= 1
        } else {
            charsInCurrentWord = 0
        }
        if (totalCharLimit <= 0 && charsInCurrentWord <= 0) {
            currentLine += char
            newText += currentLine
            currentLine = ""
            totalCharLimit = width ?? 75
            charsInCurrentWord = 30
        } else {
            currentLine += char
            totalCharLimit -= 1
        }
    }
    return newText + currentLine
}

export function reduceTimeToSeconds(time: number, timeType: string): number {
    switch (timeType) {
        case "minutes":
            return time * 60
        case "hours":
            return time * 60 * 60
        case "days":
            return time * 60 * 60 * 24
        case "weeks":
            return time * 60 * 60 * 24 * 7
        case "months":
            return time * 60 * 60 * 24 * 7 * 4
        default:
            return time
    }
}

export function padDateElement(element: number): string {
    if (element <= 9) {
        return "0" + element.toString()
    } else {
        return element.toString()
    }
}

export function divisionIdentifierUrlPrettifier(identifier: string, pretty: boolean): string {
    if (pretty) {
        return identifier.replaceAll("_", "-")
    } else {
        return identifier.replaceAll("-", "_")
    }
}