export class RankRequirement {
    requirementIdentifier: string
    shouldUseTimeUnits: boolean
    isToggle: boolean
    shouldUseRanks: boolean
    shouldUseDivisions: boolean
    shouldUseDivisionRoles: boolean
    shouldUseCertifications: boolean
    startValue: number
    inputPlaceholder: string
    alertText?: string

    constructor (
        requirementIdentifier: string,
        shouldUseTimeUnits: boolean,
        isToggle: boolean,
        shouldUseRanks: boolean,
        shouldUseDivisions: boolean,
        shouldUseDivisionRoles: boolean,
        shouldUseCertifications: boolean,
        startValue: number,
        inputPlaceholder: string,
        alertText?: string
    ) {
        this.requirementIdentifier = requirementIdentifier
        this.shouldUseTimeUnits = shouldUseTimeUnits
        this.isToggle = isToggle
        this.shouldUseRanks = shouldUseRanks
        this.shouldUseDivisions = shouldUseDivisions
        this.shouldUseDivisionRoles = shouldUseDivisionRoles
        this.shouldUseCertifications = shouldUseCertifications
        this.startValue = startValue
        this.inputPlaceholder = inputPlaceholder
        this.alertText = alertText
    }
}

export const allRequirements: RankRequirement[] = [
    new RankRequirement (
        "minimum_rank",
        false,
        false,
        true,
        false,
        false,
        false,
        -1,
        "Rank Requirement",
    ),
    new RankRequirement (
        "minimum_role",
        false,
        false,
        false,
        false,
        true,
        false,
        -1,
        "Role Requirement",
    ),
    new RankRequirement (
        "held_certification",
        false,
        false,
        false,
        false,
        false,
        true,
        0,
        "Certification Requirement",
    ),
    new RankRequirement (
        "division_restriction",
        false,
        false,
        false,
        true,
        false,
        false,
        -1,
        "Division",
        "This will prevent people within that division from applying, only use as a last resort, traffic should NOT use this as they can set patrol officer ranks"
    ),
    new RankRequirement (
        "minimum_division_playtime",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Divisional Playtime",
        "Only use this with Patrol, RTU, Dispatch, and TFU, it will not let people apply if used in any other division"
    ),
    new RankRequirement (
        "minimum_police_playtime",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Police Playtime",
        "This will allow only certain minimum total police playtime to apply"
    ),
    new RankRequirement (
        "recent_police_playtime",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Recent Police Playtime",
        "Dedicated for Recruit Firearms Officer Certification Applications - This will look for the last 31 days of playtime for any division"
    ),
    new RankRequirement (
        "minimum_playtime",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Server Playtime",
    ),
    new RankRequirement (
        "infraction_cooldown",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Time since last infraction",
    ),
    new RankRequirement (
        "held_certification",
        false,
        false,
        false,
        false,
        false,
        true,
        0,
        "Held Certification"
    ),
    new RankRequirement (
        "application_cooldown",
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        "Time between applications",
    )
]
