import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar, Grid,
    IconButton,
    Stack,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {displayNameToIdentifier} from "../utils";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {styled} from "@mui/material/styles";

export function createStatusTrackerStage(
    stageOrder: number,
    title: string,
    iconImage: React.ReactElement,
    iconColor: string,
    subtitle?: string,
    overrideDefaultBehavior?: boolean
): StatusTrackerStage {
    return {
        stageOrder: stageOrder,
        stageData: {
            identifier: displayNameToIdentifier(title),
            title: title,
            subtitle: subtitle,
            overrideDefaultBehavior: overrideDefaultBehavior,
            icon: {
                image: iconImage,
                color: iconColor
            }
        }
    }
}

interface StatusTrackerStageIcon {
    image: React.ReactElement
    color: string
}

interface StatusTrackerStageData {
    identifier: string
    title: string
    subtitle?: string
    icon: StatusTrackerStageIcon
    overrideDefaultBehavior?: boolean
}

export interface StatusTrackerStage {
    stageOrder: number
    stageData: StatusTrackerStageData
}

interface StatusTrackerProps {
    stages: StatusTrackerStage[]
    currentStage: number
    title: string
    initialExpandedState: boolean
    banner?: React.ReactElement
    sortFun?: (x: StatusTrackerStage, y: StatusTrackerStage) => number
}

const ModifiedConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#4b96fc",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#7a7777",
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export const StatusTracker = (props: StatusTrackerProps) => {
    const [expanded, setExpanded] = useState(props.initialExpandedState)

    return (
        <Accordion expanded={expanded}>
            <AccordionSummary>
                <Grid container spacing={2}>
                    {props.banner !== undefined ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.banner}
                    </Grid> : <></>}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant={"subtitle1"}>
                                {props.title}
                            </Typography>
                            <IconButton
                                sx={{ height: "30px", width: "30px" }}
                                color="primary"
                                onClick={() => setExpanded(!expanded)}
                            >
                                {expanded ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Stepper activeStep={props.currentStage} alternativeLabel connector={<ModifiedConnector/>}>
                    {props.stages && props.stages.sort(props.sortFun ?? function (x, y) { return  x.stageOrder - y.stageOrder})
                        .map((stage) => (
                            <Step key={stage.stageData.identifier}>
                            {stage.stageData.overrideDefaultBehavior === true && stage.stageOrder === props.currentStage ?
                                <StepLabel
                                    icon={
                                        <Avatar sx= {{
                                            bgcolor: stage.stageData.icon.color
                                        }}>
                                            {stage.stageData.icon.image}
                                        </Avatar>}
                                    optional={
                                        <Stack spacing={1}>
                                            <Typography variant={"subtitle2"}>{stage.stageData.title}</Typography>
                                            <Typography variant={"caption"}>{stage.stageData.subtitle}</Typography>
                                        </Stack>
                                    }
                                ></StepLabel> :
                                <StepLabel
                                    icon={
                                        <Avatar sx= {{
                                            bgcolor: props.currentStage > stage.stageOrder ? stage.stageData.icon.color : props.currentStage === stage.stageOrder ? "#4b96fc" : "#7a7777"
                                        }}>
                                            {stage.stageData.icon.image}
                                        </Avatar>}
                                    optional={
                                        <Stack spacing={1}>
                                            <Typography variant={"subtitle2"}>{stage.stageData.title}</Typography>
                                            {props.currentStage > stage.stageOrder ? <Typography variant={"caption"}>{stage.stageData.subtitle}</Typography> : <></>}
                                            {props.currentStage === stage.stageOrder ? <Typography sx={{fontStyle: 'italic'}} variant={"caption"}>In progress...</Typography> : <></>}
                                        </Stack>
                                    }
                                ></StepLabel>}
                        </Step>
                    ))}
                </Stepper>
            </AccordionDetails>
        </Accordion>
    )
}