interface UserActionType {
    key: string
    displayName: string
}

const userActionTypes: UserActionType[] = [
    { key: "steam_sync", displayName: "Synced with Steam" },
    { key: "FORCED_STEAM_SYNC", displayName: "Forced Synced with Steam" },
    { key: "edit_user", displayName: "Edited Profile" },
    { key: "delete", displayName: "Deleted" },
    { key: "forced_complaint_deanonymization", displayName: "Deanonymized an Anonymous Complaint" },
    { key: "account_status", displayName: "Account Status" },
    { key: "delete_recent_activity", displayName: "Deleted a Recent Activity Post" },
    { key: "delete_announcement", displayName: "Deleted an Announcement" },
]

export const UserActionTypes: Map<string, UserActionType> = new Map(userActionTypes.map(i => [i.key, i]))