import {useNavigate, useParams} from "react-router-dom";
import useApiCall, {makeApiCall} from "../hooks/CancellableApiCall";
import {LoadingContent} from "../components/LoadingContent";
import {Box, Divider, Paper, Stack, Typography, Button} from "@mui/material";
import {EditorContent} from "../components/editor/EditorContent";
import {AnnouncementResponse} from "../data/AnnouncementResponse";
import React, {useEffect, useState} from "react";
import {AnnouncementSubtitle} from "../components/announcement/AnnouncementPreview";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {Link as RouterLink} from 'react-router-dom'
import useUser from "../hooks/useUser";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {ConfirmationDialog} from "../components/ConfirmationDialog";

export const AnnouncementPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const loadData = useApiCall<AnnouncementResponse>({
        url: `/api/announcement/${params.id}`
    })
    const user = useUser()
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    const deleteAnnouncement = (confirm: boolean) => {
        if (confirm) {
            makeApiCall({
                url: `/api/announcement/${loadData.data?.divisionIdentifier}`,
                method: "DELETE",
                body: {
                    announcementId: params.id,
                    },
                onLoadedCallback: () => {
                    navigate(`/announcements`)
                },
                onError: () => {}
            })
        }
        setDeleteConfirm(false)
    }

    let announcement;
    if (loadData.data) {
        announcement = (
            <Stack spacing={0.5}>
                <Typography variant={"h6"}>
                    {loadData.data.title}
                </Typography>
                <AnnouncementSubtitle announcement={loadData.data}/>
                <Divider/>
                <Box sx={{pl: 2, pr: 2, pb: 2}}>
                    <EditorContent content={loadData.data?.content ?? ""}></EditorContent>
                    {user.hasPermissions(`${loadData.data?.divisionIdentifier}-announcement:create`) ?
                        <Button size={"medium"} variant="text" sx={{
                            display: {xs: "none", sm: "flex"},
                            float: "right"
                        }} component={RouterLink}
                                to={`/announcement/edit/${params.id}`}>
                            Edit Article
                            <AppRegistrationIcon/>
                        </Button> : <></>}
                        {user.hasPermissions(`${loadData.data?.divisionIdentifier}-announcement:delete`) ?
                        <Button size={"medium"} color="error" variant="text" sx={{display: {xs: "none", sm: "flex"}, float: "right" }} onClick={() => {
                            setDeleteConfirm(true)
                        }}>Delete Article
                        <DeleteForeverIcon/>
                            </Button> : <></>}
                </Box>
                <ConfirmationDialog title={"Delete Announcement"} description={"This action cannot be undone"} open={deleteConfirm} onClose={deleteAnnouncement} />
            </Stack>
        )
    }

    useEffect(() => {
        if (loadData.isError) {
            navigate("/")
        }
    }, [loadData.isError, navigate]);

    return (
        <LoadingContent isLoading={loadData.isLoading}>
            <Paper variant={"outlined"} sx={{p: 2, minHeight: 200}}>
                {announcement}
            </Paper>
        </LoadingContent>
    )
}